import React from "react"
import { graphql } from "gatsby"
import Head from "../components/head"
import Layout from "../components/layout"
import Hero from "../components/hero"
import Text from "../components/text"

export const query = graphql`
  query ($slug: String!) {
    markdownRemark (fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
      }
      html
    }
  }
`

export default function Page(props) {
  return (
    <Layout>
      <Head title={props.data.markdownRemark.frontmatter.title} robots="noindex, nofollow" />
      <Hero
        title={props.data.markdownRemark.frontmatter.title}
        description=""
        image="" />
      <Text content={props.data.markdownRemark.html} />
    </Layout>
  )
}